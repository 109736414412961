import React from "react";
import {ReactComponent as Check} from "../../static/assets/svg/check.svg";
import './AddProduct.scss'


export const PROGRESS_STATUS = {
	active: "active",
	done: "done",
}

type AddProductProgressBarType = {
	firstStatus?: string;
	secondStatus?: string;
	thirdStatus?: string;
};

const AddProductProgressBar = (props: AddProductProgressBarType) => {
	const {firstStatus, secondStatus, thirdStatus} = props

	return (
		<div className="AddProduct__progressBar">
			<div className={`AddProduct__progressBar__elem 
						 	 AddProduct__progressBar__elem--${firstStatus}`}>
				<div className="AddProduct__progressBar__elem__circle">
					<Check className="AddProduct__progressBar__elem__doneIcon"/>
					<div className="AddProduct__progressBar__elem__stepNumber">01</div>
				</div>
				<div className="AddProduct__progressBar__elem__text">
					Enter product info
				</div>
			</div>

			<div className={`AddProduct__progressBar__elem 
							 AddProduct__progressBar__elem--${secondStatus}`}>
				<div className="AddProduct__progressBar__elem__circle">
					<Check className="AddProduct__progressBar__elem__doneIcon"/>
					<div className="AddProduct__progressBar__elem__stepNumber">02</div>
				</div>
				<div className="AddProduct__progressBar__elem__text">
					Upload BOM
				</div>
			</div>

			<div className={`AddProduct__progressBar__elem 
							 AddProduct__progressBar__elem--${thirdStatus}`}>
				<div className="AddProduct__progressBar__elem__circle">
					<Check className="AddProduct__progressBar__elem__doneIcon"/>
					<div className="AddProduct__progressBar__elem__stepNumber">03</div>
				</div>
				<div className="AddProduct__progressBar__elem__text">
					Confirm & Create
				</div>
			</div>
		</div>
	);
};

export default AddProductProgressBar;
